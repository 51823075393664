a{
    text-decoration: none;
}
.menu a  p{
    color:rgba(130, 130, 130, 1);
}
.menu .active  p{
    color: rgba(239, 45, 45, 1) !important;
}

